import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { FormattedMessage } from "gatsby-plugin-intl"
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

class Aboutus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            height: 0,
            width: 0
        }
    }

    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
    }

    
    componentDidMount() {
        this.setState({height: window.innerHeight})
        window.addEventListener('resize', this.updateDimensions)
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }


    render() {

        const Heading1 = styled.h1`
            font-size: 120px;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            background: -webkit-linear-gradient(left, #E7215B , #EC6717);
            background: -o-linear-gradient(right, #E7215B, #EC6717);
            background: -moz-linear-gradient(right, #E7215B, #EC6717);
            background: linear-gradient(to right, #E7215B , #EC6717); 
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
            @media (max-width:767px) {
                font-size: 40px;
                line-height: 40px;
            }
        @media (min-width:768px) and (max-width:1500px) {
                font-size: 70px;
            }
        `

        const Main = styled.div`
            width: 100%;
            display: flex;
            align-items: center;
            background-color: #000;
        `


        const Content = styled.div`
            position: relative;
            z-index: 10
        `

        const Description = styled.p`
            color: #000;
        `

        const linkStyle = {
            color: "white",
            display: "block", 
            textAlign: "center"           
          };

        return (
            <div>
                <Helmet>
                    <title>About us</title>
                    <meta name="description" content="About us" />
                </Helmet>
                <Layout
                    isHome={false}
                    sections={['works', 'contact']}
                >
                    <Container>
                        <Content>
                            <Heading1>
                                <FormattedMessage id="aboutus.heading1" />                                
                            </Heading1>
                            <Description>
                                <FormattedMessage id="aboutus.description" />       
                            </Description>
                            <Link to="/" style={linkStyle}><FontAwesomeIcon icon={faArrowLeft}/> <FormattedMessage id="back-home" /></Link>
                        </Content>
                    </Container>
                    
                </Layout>
            </div>
        )
    }
}

export default Aboutus

